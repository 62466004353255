import Api from './Api';
import Csrf from "./Csrf";


export default {
    async sendPasswordResetLink(emailForm) {
        await Csrf.getCookie();
        return Api.post('/forgot-password', emailForm);
    },

    async updatePassword(passwordForm) {
        await Csrf.getCookie();
        return Api.post('/update-password', passwordForm);
    },
};