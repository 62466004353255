import Api from './Api';
import Csrf from "./Csrf";


export default {
    async createCourseAllocation(newCourseAllocationForm) {
        await Csrf.getCookie();
        return Api.post('/courseAllocation/create', newCourseAllocationForm);
    },

    async getAllCourseAllocations(page, per_page) {
        await Csrf.getCookie();
        return Api.get('/courseAllocations/'+ per_page +'?page='+page);
    },

    async getCourseAllocationsByStaffId(id, page, per_page) {
        await Csrf.getCookie();
        return Api.get('/courseAllocations/staff/'+ id + '/' + per_page +'?page='+page);
    },

    async getDepartmentalCourseAllocations(department_id, page) {
        await Csrf.getCookie();
        return Api.get('/get-departmental-course-allocations/' + department_id + '?page='+page);
    },

    async getAllocatedCourseInfo(id) {
        await Csrf.getCookie();
        return Api.get('/courseAllocation/course-info/'+id);
    },

    

    async getCourseAllocationsByDepartmentID(id) {
        await Csrf.getCookie();
        return Api.get('/courseAllocations/department/' + id);
    },

    async updateCourseAllocation(id, updateData) {
        await Csrf.getCookie();
        return Api.patch('/courseAllocation/update/' + id, updateData);
    },
    async deleteCourseAllocation(id) {
        await Csrf.getCookie();
        return Api.patch('/courseAllocation/destroy/' + id);
    },
};