<template>
  <v-app id="inspire">
    <v-main>
      <Navigation 
        :authenticatedUser="auth_user"
       
      >  <!-- :roles="role_names" -->
        <template v-slot:items>
          <SiderBarItems 
            :items="side_nav_items" 
            :roles="role_names"
            :permissions="permission_names" 
            usertype="academic-adviser"
            environment="main"
          />
        </template>
      </Navigation>
      <template>
        <Overview  v-if="$route.fullPath === '/academic-adviser'" v-bind:authenticatedUser="auth_user"></Overview>
        <router-view  :key="$route.fullPath"/>
      </template>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from '../../Navigation_modules/CustomNavigationDrawer'
import SiderBarItems from '../../Navigation_modules/SideBarItems'
import Overview from './AdviserOverview.vue'
import User from "../../../apis/User";


export default {
    name:"AcademicLevelAdviserDashboard",
    components: { Navigation, Overview, SiderBarItems },

    data() {
        return {
          auth_user:null,
          permission_names:[], 
          role_names:[],
          side_nav_items: [
              { text: 'Dashboard', icon: 'dashboard', route: 'overview', permissions:[] },
              { text: 'Course Registration Template', icon: 'course-reg', route: 'course-registration-template', permissions:[] },
              { text: 'My Students', icon: 'students', route: 'my-students', permissions:[]},
              { text: 'Course Registrations', icon: 'students', route: 'students-course-reg', permissions:[]},
              { text: 'Extra Credit Applications', icon: 'course-reg', route: 'students-extra-credit-applications', permissions:[]},
              { text: 'My Student Results', icon: 'students', route: 'my-student-results', permissions:[]},
              { text: 'Good Standing', icon: 'examination-office', route: 'good-standing-management', permissions: [] },
              { text: 'Progression', icon: 'progression', route: 'progression', permissions:[] },
            //  { text: 'Examination Office', icon: 'mdi-library', route: 'announcements' },
          ],
        }
    },
     mounted() {
      User.auth().then(response=>{
        this.auth_user = response.data.data;
        this.permission_names = this.auth_user.permission_names;
        this.role_names = this.auth_user.role_names;
      });
    }
}
</script>

<style>

</style>
