<template>
    <v-app id="inspire">
        <v-sheet height="1000px" align="center" color="#F5F5F5" justify="center" light>
            <v-container light v-if="!processed">
                <v-form v-model="valid" class="mt-10">
                    <v-container fluid>
                        <v-card elevation="5" class="pa-5" width="500">
                            <h5 class="text-center pa-3 font-weight-bold">
                                Enter the new password.
                            </h5>

                            <template v-if="validationError">
                                <div class="alert alert-danger">
                                    <ul id="example-1">
                                        <li>{{ validationError }}</li>
                                    </ul>
                                </div>
                            </template>

                            <v-row class="pa-5">
                                <v-col cols="12" md="12">
                                    <v-text-field outlined v-model="newPasswordForm.username" label="Staff ID / Matric No / Jamb Reg No"
                                        required></v-text-field>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <v-text-field outlined v-model="newPasswordForm.password" :rules="passwordRules"
                                        label="Password" type="password" required @focus="isPasswordFieldFocused = true" @blur="isPasswordFieldFocused = false">
                                        <template v-slot:append-outer>
                                            <v-icon :color="passwordIsValid ? 'green' : ''">
                                                {{ passwordIsValid ? 'mdi-check' : '' }}
                                            </v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>


                                <v-col cols="12" md="12">
                                    <v-text-field outlined v-model="newPasswordForm.password_confirmation"
                                        label="Confirm Password" type="password" required
                                        :rules="[v => !!v || 'Confirm password is required', v => v === newPasswordForm.password || 'Passwords do not match']"></v-text-field>
                                </v-col>

                                <v-list dense class="password-rules-list" v-show="isPasswordFieldFocused">
                                        <v-list-item-group>
                                            <v-list-item v-for="(rule, index) in passwordRules" :key="index">
                                                <v-list-item-content>
                                                    <v-row>
                                                        <v-col cols="auto">
                                                            <v-icon :color="isRuleSatisfied(rule) ? 'green' : 'red'"
                                                                class="mr-2 text-small">
                                                                {{ isRuleSatisfied(rule) ? 'mdi-check' : 'mdi-close' }}
                                                            </v-icon>
                                                        </v-col>
                                                        <v-col style="text-align: start !important;">
                                                            <span class="text-small text-left" style="text-align: left;">{{ rule.message }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                            </v-row>

                            <v-card-actions class="justify-end py-5">
                                <v-row>
                                    <v-col>
                                        <v-btn color="primary" :disabled="!passwordIsValid || !confirmPasswordIsValid"
                                            @click.prevent="resetPassword">
                                            <strong>Save New Password</strong>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>

                        <v-divider class="mx-5 mt-8 mb-5"></v-divider>
                    </v-container>
                </v-form>
            </v-container>

            <v-container fluid light v-if="processed">
                <v-card elevation="5" class="pa-5 mt-10" width="500">
                    <div class="alert alert-success" v-if="success">
                        <h4>Password reset successful!</h4>
                        <v-spacer></v-spacer>
                        <v-btn text @click="loadLoginPage">
                            <strong>Proceed to login</strong>
                        </v-btn>
                    </div>
                    <div class="alert alert-danger" v-if="!success">
                        <h4>We are unable to reset the password!</h4>
                        <h6>This may be due to any of the following:</h6>
                        <ul>
                            <li>Your password reset token may have expired</li>
                            <li>You are not authorized to reset the password for this user</li>
                        </ul>
                        <v-spacer></v-spacer>
                        <v-btn text @click="loadLoginPage">
                            <strong>Return to the login page</strong>
                        </v-btn>
                    </div>
                </v-card>
            </v-container>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="50"></v-progress-circular>
            </v-overlay>
        </v-sheet>
    </v-app>
</template>

<script>
import PasswordResetAPI from "../../../apis/PasswordReset";

export default {
    props: ["resettoken"],
    watch: {
        'newPasswordForm.password': {
            handler() {
                this.passwordIsValid = this.passwordRules.every((rule) => rule.validator(this.newPasswordForm.password) === true);
            },
            deep: true,
        },
        'newPasswordForm.password_confirmation': {
            handler() {
                this.confirmPasswordIsValid = this.newPasswordForm.password_confirmation === this.newPasswordForm.password;
            },
            deep: true,
        },
        overlay(val) {
            val && setTimeout(() => {
                this.overlay = false;
            }, 2000);
        },
    },

    computed: {
        passwordRules() {
            return [
                { validator: (v) => v && v.length >= 8, message: "Password must be at least 8 characters" },
                { validator: (v) => /[a-z]/.test(v), message: "Password must contain at least 1 lowercase letter" },
                { validator: (v) => /[A-Z]/.test(v), message: "Password must contain at least 1 uppercase letter" },
                { validator: (v) => /\d/.test(v), message: "Password must contain at least 1 number" },
            ];
        },
    },

    data() {
        return {
            processed: false,
            success: false,
            overlay: false,
            valid: false,
            validationError: "",
            newPasswordForm: {
                username: "",
                password: "",
                password_confirmation: "",
                token: this.resettoken
            },
            errors: [],
            passwordIsValid: false,
            confirmPasswordIsValid: false,
            isPasswordFieldFocused: false,
        };
    },

    methods: {
        isRuleSatisfied(rule) {
            return rule.validator(this.newPasswordForm.password) === true;
        },

        resetPassword() {
            this.overlay = true;
            PasswordResetAPI.updatePassword(this.newPasswordForm)
                .then(response => {
                    this.processed = true;
                    this.overlay = false;
                    this.success = response.data.status == "success" ? true : false;
                    window.scrollTo({ top: 0, behavior: 'smooth' });

                })
                .catch(error => {
                    this.validationError = Object.values(error.response.data.errors).flat()[0];
                }).finally(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
        },

        loadLoginPage() {
            let url = window.origin + '/login';
            window.location.href = url;
        }
    },
};
</script>

<style scoped>
.text-small {
    font-size: 12px;
}

.password-rules-list {
    margin-top: 0px;
}

.password-rules-list v-list-item {
    padding: 0 0;
}

.password-rules-list v-icon {
    /* margin-right: 4px; */
}
</style>

